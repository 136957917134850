import React from 'react'
import { Link } from 'gatsby'
import {
  logoSVGT1,
  logoSVGT2,
  logoSVGT3,
  logoSVGT4,
  logoSVGT5,
  logoSVGT6,
  logoSVGT7,
} from '../../static/svgtext'
// import logo from '../../static/img/mstile-70x70.png'
import { HomeColor, ThirdPage, FourthPage, MinistriesName, SixthPage } from '../components/Sampler'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand" style={{justifyContent: 'flex-end'}}>
            <Link to="/" className="navbar-item" title="Logo" id="imgLogo">
              {/* <img src={logo} alt="homeLogo" style={{ width: '26px', alignSelf: 'right' }} /> */}
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                height="26px" viewBox="0 0 1140.000000 1158.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: '26px', alignSelf: 'right' 
                }}>

                <g transform="translate(0.000000,1158.000000) scale(0.100000,-0.100000)"
                  fill={HomeColor} stroke="none">
                  <path d={logoSVGT1} />
                  <path d={logoSVGT2} />
                  <path d={logoSVGT3} />
                  <path d={logoSVGT4} />
                  <path d={logoSVGT5} />
                  <path d={logoSVGT6} />
                  <path d={logoSVGT7} />
                </g>
              </svg>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              style={{marginLeft: '0'}}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/events">
                Events
              </Link>
              <Link className="navbar-item" to="/sermons">
                {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "Livestream" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "Sermons" : "History"}
              </Link>
              {MinistriesName === "Removed" ? null : 
              <Link className="navbar-item" to="/ministries">
                {MinistriesName}
              </Link>
              }
              {SixthPage === "Removed" ? null : 
              <Link className="navbar-item" to="/giving">
                Giving
              </Link>
              }
              <Link className="navbar-item" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
