import {
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT
} from '../../static/svgtext'

export const ContainerSVGT = distressedcircleSVGT
export const ContainerChoice = "distressedC"
export const MainFont = 'Playfair Display SC'
export const SubFont = 'Poppins'

export const HomeColor = "#2B4E8C"
export const AboutColor = "#d63340"
export const EventsColor = "#d69432"
export const LiveColor = "#242424"
export const OLDColor = "#bfaa8d"
export const MinistriesColor = "#3d466c"
export const GivingColor = "#46a8a3"
export const ContactColor = "#1a7193"
export const ThirdPage = "Video (YouTube)"
export const MinistriesName = "Get Involved"
export const SixthPage = 'Tithe.ly'